import Projects from "../components/projects/Projects"
import Works from "../components/works/Works"

const ProjectPage = () => {
    return (
        <>
            <div >
                <Works />
                <Projects />
            </div>

        </>
    )


}

export default ProjectPage